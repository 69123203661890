<template>
  <div id="message-detail">
    <!-- <AppContainer
      :useBackground="!isLoading ? true : false"
      :useShadow="!isLoading ? true : false"
    > -->
    <!-- <template v-if="isLoading">
        <AppLoading />
      </template>
      <template v-else> -->
    <div class="message-detail">
      <div
        ref="header"
        class="header d-flex align-items-center justify-content-between"
        id="header"
      >
        <template v-if="firebaseMembers && firebaseMembers[0]">
          <div class="d-flex align-items-center">
            <button
              class="btn btn-back"
              @click="$router.go(-1)"
              v-if="screenWidth < 1080"
            >
              <img :src="require('@/assets/image/left-arrow.svg')" alt="back" />
            </button>
            <div
              class="header__image d-flex"
              v-if="firebaseRoom.type === 3"
              :style="
                propsIsPC
                  ? 'width: 58px; height: 58px'
                  : 'width: 47px; height: 47px'
              "
            >
              <AvatarBox
                :imgUrl="firebaseOwner.imageUrl"
                :imgSize="propsIsPC ? '41.96px' : '34px'"
                :imgAlt="firebaseOwner.nickname"
              />
              <AvatarBox
                :imgUrl="firebaseMembers[0].imageUrl"
                :imgSize="propsIsPC ? '41.96px' : '34px'"
                :imgAlt="firebaseMembers[0].nickname"
              />
            </div>
            <div
              class="header__image d-flex"
              v-else
              :style="
                propsIsPC
                  ? 'width: 58px; height: 58px'
                  : 'width: 47px; height: 47px'
              "
            >
              <div v-if="firebaseMembers[0].id != 0">
                <router-link
                  :to="{
                    name:
                      firebaseMembers[0].sex === 1
                        ? 'MenProfile'
                        : 'CastProfile',
                    params: { id: firebaseMembers[0].id }
                  }"
                >
                  <AvatarBox
                    :imgUrl="firebaseMembers[0].imageUrl"
                    :imgSize="propsIsPC ? '58px' : '47px'"
                    :imgAlt="firebaseMembers[0].nickname"
                  />
                </router-link>
              </div>
              <div v-else>
                <AvatarBox
                  :imgUrl="firebaseMembers[0].imageUrl"
                  :imgSize="propsIsPC ? '58px' : '47px'"
                  :imgAlt="firebaseMembers[0].nickname"
                />
              </div>
            </div>

            <span
              v-if="firebaseRoom.type === 3"
              class="f-w6 nickname-cl"
              :class="propsIsPC ? 'fs-16' : 'fs-14'"
              @click="showProfileOfUser()"
            >
              {{
                formatText(
                  firebaseMembers.reduce((roomName, member) => {
                    roomName = roomName + ", " + member.nickname;
                    return roomName;
                  }, firebaseOwner.nickname),
                  40
                )
              }}
            </span>
            <span
              v-else
              class="f-w6 nickname-cl"
              :class="propsIsPC ? 'fs-16' : 'fs-14'"
              @click="showProfileOfUser()"
            >
              {{ formatText(firebaseMembers[0].nickname, 40) }}
            </span>
          </div>

          <img
            v-if="authUser.sex === 1 && firebaseRoom.type !== 1"
            class="f-w3 cursor-pointer"
            @click="handleShowReport"
            src="@/assets/image/icon-info.svg"
          />

          <img
            v-if="
              authUser.sex === 2 && firebaseApplicant && firebaseRoom.type !== 1
            "
            class="f-w3 cursor-pointer"
            @click="showReport = true"
            src="@/assets/image/icon-info.svg"
          />
          <MessageEventConfirm
            v-if="
              (adsRoom.status === '確定済み' ||
                adsRoom.status === '集合済み') &&
                firebaseRoom.type === 3 &&
                authUser.sex === 2 &&
                firebaseApplicant &&
                (firebaseApplicant.status === 0 ||
                  firebaseApplicant.status === 1)
            "
            :showModal="openConfirm"
            :status="firebaseApplicant.status"
            @change-show-confirm="openConfirm = false"
            @confirm-event="femaleChangeEventStatus(firebaseApplicant.status)"
          />
          <MessageDelayConfirm
            v-if="
              adsRoom.status === '確定済み' &&
                firebaseRoom.type === 3 &&
                authUser.sex === 1
            "
            :showModal="openDelayConfirm"
            @change-show-confirm="openDelayConfirm = false"
            @send-reason="sendReason"
          />
        </template>
      </div>
      <div
        id="content"
        class="content"
        :class="firebaseRoom.type === 1 ? 'system-content' : ''"
      >
        <scroll-loader :loader-method="getList" :loader-disable="disable">
        </scroll-loader>
        <div class="chat" id="chat">
          <div v-for="(messages, index) in listMessage" :key="index">
            <div
              class="chat-date d-flex align-items-center justify-content-center"
            >
              <div class="chat-date__div"></div>
              <span class="chat-date__span f-w3 fs-10">{{
                formatDateTime(index)
              }}</span>
              <div class="chat-date__div"></div>
            </div>
            <MessageBox
              v-for="(message, index) in messages"
              :key="index"
              :boxClass="
                message.userId !== null &&
                message.type !== 5 &&
                message.type !== 6
                  ? message.userId.id === authUser.user_id.toString()
                    ? 'rtl'
                    : ''
                  : 'message-system'
              "
              :propsLength="
                messages.length > 1 && !messages.lastIndexOf(message)
              "
              :propsIsPC="propsIsPC"
              :propsMessage="message"
              :propsAd="adsRoom"
              :ref="'message-' + message.id"
              :targetCalendar="targetCalendar"
              @load-success="scrollToElement(message.id)"
              @change-input-mess="changeInputMess"
            />
          </div>
        </div>

        <!-- <div v-if="imageUrl.length > 0" class="content__image-msg">
            <button class="btn btn-remove" @click="removeImage()">
              <img :src="require('@/assets/image/close.svg')" alt="remove image" />
            </button>
            <img alt="image message" :src="imageUrl" @load="loadImage()" />
          </div> -->
      </div>
      <div ref="tool" v-if="firebaseRoom.type !== 1" class="tool" id="tool">
        <div class="tool__input d-flex align-items-center">
          <button
            class="btn btn-show-sticker"
            id="btn-show-sticker"
            @click="msgSuggestToggle()"
          >
            <a href="#" title="End" class="arrow-up up"></a>
          </button>
          <form @submit.prevent="handleSubmit">
            <div
              class="d-flex _other align-items-center"
              :class="showOther ? 'active' : 'active'"
            >
              <!--              <button-->
              <!--                type="button"-->
              <!--                class="btn btn-more"-->
              <!--                @click="showOtherOptions(showOther)"-->
              <!--                v-if="authUser.sex === 1 && monthlyPlanStatus"-->
              <!--              >-->
              <!--                <img-->
              <!--                  :src="require('@/assets/image/arrow-right.svg')"-->
              <!--                  alt="show more"-->
              <!--                />-->
              <!--              </button>-->
              <!--              <label class="btn btn-img mb-0">-->
              <!--                <img-->
              <!--                  :src="require('@/assets/image/image-message.svg')"-->
              <!--                  alt="share image"-->
              <!--                />-->
              <!--                <input-->
              <!--                  id="file-input"-->
              <!--                  type="file"-->
              <!--                  name="image"-->
              <!--                  accept="image/png, image/jpeg"-->
              <!--                  class="invisible"-->
              <!--                  v-if="-->
              <!--                    (authUser.sex === 2 && authUser.identity_status != 3) ||-->
              <!--                      (authUser.sex === 1 && !monthlyPlanStatus)-->
              <!--                  "-->
              <!--                  @click.prevent="HandleInputFile($event)"-->
              <!--                />-->
              <!--                <input-->
              <!--                  id="file-input"-->
              <!--                  type="file"-->
              <!--                  name="image"-->
              <!--                  accept="image/png, image/jpeg"-->
              <!--                  class="invisible"-->
              <!--                  v-else-->
              <!--                  @change="HandleInputFile($event)"-->
              <!--                />-->
              <!--              </label>-->
              <!--              <button-->
              <!--                class="btn btn-gift"-->
              <!--                type="button"-->
              <!--                @click="showGiftBox"-->
              <!--                v-if="authUser.sex === 1 && firebaseRoom.type === 2"-->
              <!--              >-->
              <!--                <img-->
              <!--                  :src="require('@/assets/image/giftbox.svg')"-->
              <!--                  alt="sent gift"-->
              <!--                />-->
              <!--              </button>-->
            </div>
            <!-- <form @submit.prevent="handleSubmit"> -->
            <div class="d-flex form-message">
              <textarea
                id="textarea"
                placeholder="メッセージを入力"
                v-model="inputMsg"
                rows="1"
                class="f-w3"
                @keyup="focusMessageIn"
                @keydown="pressEnter"
                @focusout="focusMessageOut"
                @focus="focusMessage"
                @click="showOtherOptions('hide')"
                @keyup.enter="handleSubmitKeyboard"
                @change="changeText"
                maxlength="2000"
                :readonly="readonlyMsg == 1"
              ></textarea>
              <button
                v-if="
                  (checkMessageFocus ||
                    inputMsg.length > 0 ||
                    imageUrl !== '') &&
                    (!showOther || authUser.sex === 2)
                "
                class="btn btn-send d-flex align-items-center"
              >
                <img
                  :src="require('@/assets/image/send-message.svg')"
                  alt="send message"
                />
              </button>
            </div>
          </form>
        </div>
        <MessageSuggest
          ref="suggest_message"
          v-model="inputMsg"
          :show="showSuggest"
          :lastMsg="lastMsg"
          :monthlyPlanStatus="monthlyPlanStatus"
          @close-suggest="closeSuggest()"
          @calculate-height="calculateHeight()"
          @pick-message="pickMessage($event)"
          @pick-suggest-image="pickSuggestImage($event)"
        />
        <MessageGift
          ref="suggest_gift"
          :showStatus="showGift"
          :roomId="roomId"
          :toUserId="firebaseMembers[0] && firebaseMembers[0].id"
          @updated-success="updateGift"
          @show-gift="showGiftBox"
          @show-gift-shop="showShopGift($event)"
          @scroll-bottom="scrollToBottom"
        />
        <MessageGiftShop
          ref="gift_shop"
          :showStatus="showGiftShop"
          :roomId="roomId"
          :toUserId="firebaseMembers[0] && firebaseMembers[0].id"
          @updated-success="updateGift"
          @show-gift-shop="showShopGift($event)"
          @scroll-bottom="scrollToBottom"
        />
      </div>
      <CardFooter ref="footer" prefer="message" v-if="screenWidth < 1080" />
    </div>
    <ModalLayout ref="identifyPopup">
      <IdentifyPopup
        @identify="identify"
        :identity_status="authUser.identity_status"
        :sex="2"
      />
    </ModalLayout>
    <ModalLayout ref="addMonthlyPlanPopup">
      <AddMonthlyPlanPopup
        @close-modal="closeAddMonthlyPlanPopup"
        :roomId="roomId"
      />
    </ModalLayout>
    <ModalLayout
      ref="continueDatePopup"
      :showModal="showContinueDatePopup"
      @closeModal.native="closeContinueDatePopup()"
      @click.native="closeContinueDatePopup()"
    >
      <ContinueDatePopup :sex="authUser.sex" />
    </ModalLayout>
    <ReportModal
      :showModal="showReport"
      @change-show-confirm="showReport = false"
      @show-report-confirm="showReportConfirm = true"
    />
    <ReportConfirm
      :showModal="showReportConfirm"
      :userIds="firebaseMembers"
      :authUser="authUser"
      :userReports="userReports"
      @change-show-confirm="showReportConfirm = false"
      @send-report="sendReport"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import firebase from "@/firebase";
import service from "@/utils/axios";
import _ from "lodash";
import CardFooter from "@/components/CardFooter.vue";
import AvatarBox from "../Common/AvatarBox";
import MessageBox from "../Men/MessageBox";
import MessageGift from "../Men/MessageGift";
import IdentifyPopup from "@/components/IdentifyPopup.vue";
import ContinueDatePopup from "@/components/ContinueDatePopup";
import AddMonthlyPlanPopup from "@/components/AddMonthlyPlanPopup";
import MessageEventConfirm from "./MessageEventConfirm.vue";
import MessageDelayConfirm from "./MessageDelayConfirm.vue";
import ReportModal from "@/components/Message/ReportModal.vue";
import ReportConfirm from "@/components/Message/ReportConfirm.vue";
import MessageSuggest from "./MessageSuggest.vue";
import MessageGiftShop from "../Men/MessageGiftShop.vue";
import { urlText } from "@/utils/ui";
import { ADS_API_URL } from "@/api/api";
import { MESSAGES_TYPE, SEND_CONTINUE_POPUP } from "@/utils/const";
import "vue2-datepicker/locale/ja";

const storage = firebase.storage;

export default {
  name: "MessageDetail",
  components: {
    AvatarBox,
    MessageBox,
    MessageGift,
    MessageSuggest,
    MessageGiftShop,
    MessageEventConfirm,
    MessageDelayConfirm,
    CardFooter,
    ReportModal,
    ReportConfirm,
    IdentifyPopup,
    ContinueDatePopup,
    AddMonthlyPlanPopup
  },
  data() {
    return {
      readonlyMsg: 0,
      resize_content: null,
      inputMsg: "",
      inputImg: "",
      imageUrl: "",
      showOther: false,
      showGift: false,
      showSuggest: false,
      showGiftShop: false,
      screenWidth: "",
      listMessage: [],
      checkMessageFocus: false,
      roomId: null,
      listUser: [],
      statusEvent: "met",
      openConfirm: false,
      openDelayConfirm: false,
      readUsers: null,
      disable: false,
      showReport: false,
      showReportConfirm: false,
      userReports: [],
      isInitScroll: false,
      lastMsg: [],
      showContinueDatePopup: false,
      targetCalendar: false,
      days: [],
      calendarMsg: null,
      chatFreeDay: 6 //day
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "common/isLoading",
      authUser: "auth/user",
      adsRoom: "firebase/ads",
      firebaseRoom: "firebase/room",
      firebaseMessages: "firebase/messages",
      listUserIdsBlock: "auth/userIdsBlock",
      firebaseApplicant: "firebase/applicant",
      firebaseOwner: "firebase/owner",
      firebaseMembers: "firebase/members",
      monthlyPlanStatus: "payment-stripe/monthlyPlanStatus"
    })
  },
  props: {
    propsRoomId: {
      type: String,
      default: null
    },
    propsIsPC: {
      type: Boolean,
      default: false
    }
  },
  async created() {
    this.$store.dispatch("common/setIsLoading", true);
    this.screenWidth = window.innerWidth;
    this.roomId = this.$route.params.id;
    await this.setRoomId(this.roomId);
    await this.resetListMessage();
    this.getUserIdsBlock();
    this.getRoom({
      roomId: this.roomId,
      userId: this.authUser.user_id
    });
    if (!this.propsIsPC) {
      this.getUnread({
        userId: this.authUser.user_id
      });
    }
    if (this.authUser.sex === 1) {
      await this.getPaymentMonthPacket();
    }
    this.$store.dispatch("common/setIsLoading", false);
    this.calculateHeight();
    if (this.authUser.sex === 2 && this.authUser.identity_status != 3) {
      this.readonlyMsg = 1;
    }

    const today = new Date();
    const createdAt =
      this.authUser.profile_completed_created_at != null
        ? new Date(this.authUser.profile_completed_created_at)
        : new Date(this.authUser.created_at);
    const dateFreeNum = this.daysBetween(today, createdAt);

    if (
      this.authUser.sex === 1 &&
      !this.monthlyPlanStatus &&
      dateFreeNum > this.chatFreeDay
    ) {
      this.readonlyMsg = 1;
    }
    // if (
    //   (this.authUser.sex === 2 && this.authUser.identity_status != 3) ||
    //   (this.authUser.sex === 1 && !this.monthlyPlanStatus)
    // ) {
    //   this.showSuggest = true;
    // }
    this.showSuggest = true;
  },
  mounted() {
    // const vm = this;
    document.querySelector("html").style.cssText = "overflow: auto";
    const slider = document.getElementById("tool");
    const header = document.getElementById("header");
    slider.addEventListener("touchstart", swipe);
    slider.addEventListener("touchmove", swipe);
    header.addEventListener("touchstart", swipe);
    header.addEventListener("touchmove", swipe);

    function swipe(event) {
      if (event.type === "touchmove" && event.target.id !== "textarea") {
        event.preventDefault();
      }
    }
    window.onresize = this.calculateHeight;
    this.resize_content = new ResizeObserver(() => {
      let contentBox = document.getElementById("content");
      if (contentBox) {
        contentBox.scrollTop = contentBox.scrollHeight;
      }
    });
    this.resize_content.observe(document.getElementById("content"));
  },
  beforeDestroy() {
    const content = document.getElementById("content");
    document.querySelector("html").style.cssText = "overflow: unset";
    document.querySelector("body").style.cssText = "overflow: unset";
    if (content) {
      this.resize_content.unobserve(content);
    }
  },
  watch: {
    firebaseMessages(newVal, oldVal) {
      if (
        (this.firebaseMessages[0] &&
          this.firebaseMessages[0].roomId === this.$route.params.id) ||
        !this.firebaseMessages[0]
      ) {
        let cancelAd = this.firebaseMessages.find(
          item => item.type === 4 || item.type === 9
        );
        let pickUpMsg = this.firebaseMessages.find(
          item =>
            item.type === 5 &&
            parseInt(item.userId.id) === parseInt(this.authUser.user_id)
        );
        let dropOffMsg = this.firebaseMessages.find(
          item =>
            item.type === 6 &&
            parseInt(item.userId.id) === parseInt(this.authUser.user_id)
        );
        this.calendarMsg = this.firebaseMessages.find(
          item => item.type === MESSAGES_TYPE.CALENDAR
        );
        if (pickUpMsg && this.firebaseApplicant) {
          Vue.set(this.firebaseApplicant, "status", 1);
        }
        if (dropOffMsg && this.firebaseApplicant) {
          Vue.set(this.firebaseApplicant, "status", 2);
        }
        if (cancelAd && this.adsRoom) {
          Vue.set(this.adsRoom, "status", "確定後キャンセル");
        }
        this.listMessage = [...this.firebaseMessages];
        if (this.listMessage.length > 0) {
          this.lastMsg = [];
          this.lastMsg.push(this.listMessage[0]);
        } else {
          this.lastMsg = [];
        }
        this.listMessage = this.listMessage
          .reverse()
          .reduce((listMes, message) => {
            const date = this.getDate(message.createdAt);
            if (!listMes[date]) {
              listMes[date] = [];
            }
            listMes[date].push(message);

            return listMes;
          }, {});
      }
      if (oldVal.length == newVal.length || newVal.length < 20) {
        this.disable = true;
      } else {
        this.disable = false;
      }
      this.$nextTick(() => {
        if (newVal.length - oldVal.length > 1) {
          if (this.firebaseMessages[oldVal.length]) {
            const topRefs =
              "message-" + this.firebaseMessages[oldVal.length].id;
            this.$refs[topRefs][0].$el.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest"
            });
            if (oldVal.length != 0) {
              document.getElementById("content").scrollTop -= 0;
            } else {
              this.scrollToBottom();
            }
          }
        } else {
          if (newVal.length - oldVal.length == 1) {
            // const topRefs = "message-" + this.firebaseMessages[0].id;
            // this.$refs[topRefs][0].$el.scrollIntoView({
            //   behavior: "smooth",
            //   block: "end",
            //   inline: "nearest"
            // });
            this.scrollToBottom();
          }
        }
      });
    },
    async propsRoomId() {
      this.showSuggest = true;
      this.showGift = false;
      this.showGiftShop = false;
      this.inputMsg = "";
      this.imageUrl = "";
      this.roomId = this.$route.params.id;
      let textarea = document.querySelector("textarea");
      if (textarea) {
        if (window.innerWidth > 1080) {
          textarea.style.cssText = "height:auto; height: 52px;";
        } else {
          textarea.style.cssText = "height:auto; height: 40px;";
        }
      }
      await this.setRoomId(this.roomId);
      await this.resetListMessage();
      this.getUserIdsBlock();
      this.getRoom({
        roomId: this.roomId,
        userId: this.authUser.user_id
      });
      let elClassListMgs = document.getElementById("suggesListMsg");
      elClassListMgs.classList.add("not-show");
      let elBtn = document.getElementById("btn-close");
      elBtn.classList.add("rotate-btn");
      this.calculateHeight();
    },
    "firebaseRoom.type"() {
      this.calculateHeight();
    },
    "firebaseRoom.createdAt"() {
      const approvedDate = this.$dayjs(this.authUser.identity_date_approved);
      const firebaseRoomCreated = this.$dayjs(
        this.firebaseRoom.createdAt.toDate()
      );
      if (
        this.authUser.sex === 2 &&
        approvedDate > firebaseRoomCreated &&
        this.firebaseRoom.type === 2 &&
        !this.firebaseRoom.sendContinuePopup &&
        this.firebaseRoom.checkDisplayPopup
      ) {
        this.showContinueDatePopup = true;
      }
    },
    monthlyPlanStatus(value) {
      if (value) {
        const acceptMonthlyPlanDate = this.$dayjs(
          this.monthlyPlanStatus.created
        );
        const firebaseRoomCreated = this.$dayjs(
          this.firebaseRoom.createdAt.toDate()
        );
        if (
          this.authUser.sex === 1 &&
          acceptMonthlyPlanDate > firebaseRoomCreated &&
          this.firebaseRoom.type === 2 &&
          !this.firebaseRoom.sendContinuePopup &&
          this.firebaseRoom.checkDisplayPopup
        ) {
          this.showContinueDatePopup = true;
        }
      }
    }
  },
  metaInfo() {
    return {
      title: "詳細メッセージ",
      titleTemplate: "%s | PreGo"
    };
  },
  methods: {
    ...mapActions("auth", ["getInfoUser"]),
    ...mapActions("auth", ["getUserIdsBlock"]),
    ...mapActions("firebase", [
      "getRoom",
      "createMessage",
      "getUnread",
      "setRoomId",
      "sendEmail",
      "resetListMessage"
      // "getListRoomSystem"
    ]),
    ...mapActions("payment-stripe", ["getPaymentMonthPacket"]),
    async getList() {
      if (
        this.firebaseMessages[this.firebaseMessages.length - 1] &&
        this.firebaseMessages.length >= 20 &&
        this.isInitScroll
      ) {
        this.getRoom({
          roomId: this.roomId,
          userId: this.authUser.user_id,
          lastMessage: this.firebaseMessages[this.firebaseMessages.length - 1]
            .id
        });
      }
      this.isInitScroll = true;
    },
    calculateHeight() {
      // NOTE: test resize by view port
      this.$nextTick(() => {
        setTimeout(() => {
          let headerHeight = document.getElementById("header")
            ? document.getElementById("header").offsetHeight
            : 0;
          let toolHeight =
            this.$refs.tool || this.firebaseRoom.type !== 1
              ? this.$refs.tool.clientHeight
              : 0;
          if (window.innerWidth < 1080) {
            let footerHeight = this.$refs.footer
              ? this.$refs.footer.$el.clientHeight
              : 0;
            // let vh = Math.max(
            //   document.documentElement.clientHeight || 0,
            //   window.innerHeight || 0
            // );
            let msgSuggest = 0;
            document.getElementById("content").style.height =
              "calc(100vh - " +
              (headerHeight + toolHeight + footerHeight + msgSuggest) +
              "px)";
          } else {
            let footerHeight = document.querySelector(".footer").offsetHeight;
            document.getElementById("content").style.height =
              "calc(100vh - " +
              (headerHeight + toolHeight + footerHeight) +
              "px)";
          }
        }, 300);
      });
    },
    showShopGift(data) {
      this.showGiftShop = !this.showGiftShop;
      if (data === "tip") {
        this.showGift = false;
      } else {
        this.showGift = !this.showGift;
      }
      this.calculateHeight();
    },
    pickMessage(event) {
      this.inputMsg = event + " ";
      this.showOther = false;
      document.querySelector("textarea").blur();
      document.querySelector("textarea").focus();
      this.$nextTick(() => {
        const textarea = document.getElementById("textarea");
        if (event.length > 13) {
          textarea.style.cssText = "height:" + textarea.scrollHeight + "px";
          this.calculateHeight();
        } else {
          if (window.innerWidth > 1080) {
            textarea.style.cssText = "height:auto; height: 52px;";
          } else {
            textarea.style.cssText = "height:auto; height: 40px;";
          }
        }
      });
    },
    async pickSuggestImage(params) {
      let data = {
        roomId: this.roomId,
        message: {
          type: 1,
          userId: this.authUser.user_id,
          message: "",
          imageUrl: params.path,
          giftId: null,
          createdAt: new Date(),
          readUsers: this.readUsers,
          isSuggest: 1,
          suggestText: params.content
        }
      };
      const otherUser = Object.entries(this.readUsers).filter(
        ([key]) => key != this.authUser.user_id
      );
      if (otherUser.length > 0) {
        const otherUserId = otherUser[0].find(() => true);
        let url = window.location.href;
        let titleMsgLine = "";
        if (params.type == 0) {
          titleMsgLine = "ゴルフのお誘いがありました";
        } else {
          titleMsgLine = "お相手から返答がありました";
        }
        await this.$store.dispatch("auth/sendMessageToNotifiedByLine", {
          user_id: otherUserId,
          message: "",
          channel_url: url,
          title: titleMsgLine
        });
      }

      await this.$store.dispatch("firebase/createMessage", data);
      let payload = {
        user_id: this.authUser.user_id,
        content: params.content,
        type: params.type
      };
      await this.$store.dispatch("firebase/saveMessageLogs", payload);
      if (
        (this.authUser.sex === 2 && this.authUser.identity_status != 3) ||
        (this.authUser.sex === 1 && !this.monthlyPlanStatus)
      ) {
        this.closeSuggest();
      } else if (params.type == 1) {
        let data = {
          roomId: this.roomId,
          message: {
            type: MESSAGES_TYPE.CALENDAR,
            userId: this.authUser.user_id,
            message: "",
            imageUrl: "",
            giftId: null,
            createdAt: new Date(),
            from: this.authUser.sex,
            readUsers: this.readUsers
          }
        };
        await this.$store.dispatch("firebase/createMessage", data);
      }
      this.calculateHeight();
      this.sendNotification();
    },
    updateGift() {
      this.calculateHeight();
    },
    showGiftBox() {
      if (this.authUser.sex === 1 && !this.monthlyPlanStatus) {
        this.$refs.addMonthlyPlanPopup.openModal();
        return;
      }
      this.showSuggest = false;
      this.showGift = this.showGiftShop ? false : !this.showGift;
      this.showGiftShop = false;
      this.calculateHeight();
    },
    closeSuggest() {
      this.showSuggest = false;
      this.calculateHeight();
    },
    getDate(data) {
      data = data.toDate();
      let year = data.getFullYear();
      let month = data.getMonth();
      let day = data.getDate();

      return year + "/" + month + "/" + day;
    },
    formatTime(time) {
      if (time < 10) {
        time = "0" + time;
      }

      return time;
    },
    formatDateTime(data) {
      const date = data.split("/").map(element => parseInt(element));
      let dateNow = new Date();
      let time = "";
      if (
        date[0] === dateNow.getFullYear() &&
        date[1] === dateNow.getMonth() &&
        date[2] === dateNow.getDate()
      ) {
        time = "今日";
      } else {
        if (date[0] !== dateNow.getFullYear()) {
          let year = date[0];
          time += year + "/";
        }
        let day = date[2] < 10 ? "0" + date[2] : date[2];
        let month = date[1] + 1 < 10 ? "0" + (date[1] + 1) : date[1] + 1;
        time += month + "/" + day + " ";
      }

      return time;
    },
    formatText(text, numberLetter) {
      if (text) {
        if (text.length > numberLetter) {
          return text.substring(0, numberLetter) + "...";
        } else {
          return text;
        }
      }
    },
    removeImage() {
      document.getElementById("file-input").value = "";
      this.imageUrl = "";
    },
    async handleSubmit(e) {
      // if (e.ctrlKey || e.altKey) {
      //   var val = e.target.value;
      //   var selStart = e.target.selectionStart;
      //   e.target.value =
      //     val.slice(0, selStart) + "\n" + val.slice(e.target.selectionEnd);
      //   e.target.selectionEnd = e.target.selectionStart = selStart + 1;
      //   if (e.target.selectionEnd === e.target.textLength) {
      //     let textarea = document.getElementById("textarea");
      //     textarea.scrollTop = textarea.scrollHeight;
      //   }
      // } else {
      if (this.inputMsg.trim() === "") {
        this.inputMsg = "";
      }
      if (this.inputMsg.length === 0 && this.inputImg === "") {
        e.preventDefault();
      } else {
        if (this.inputMsg.trim() !== "" && this.inputMsg.length <= 2000) {
          let data = {
            roomId: this.roomId,
            message: {
              type: 1,
              userId: this.authUser.user_id,
              message: urlText(this.inputMsg.trim()),
              imageUrl: "",
              giftId: null,
              createdAt: new Date(),
              readUsers: this.readUsers
            }
          };
          await this.$store
            .dispatch("firebase/createMessage", data)
            .then(() => {
              if (this.targetCalendar) {
                this.$store.dispatch("firebase/updateRoomSendContinuePopup", {
                  roomId: this.roomId,
                  value: SEND_CONTINUE_POPUP.DELETE_MSG
                });
                this.$store
                  .dispatch("firebase/deleteMessageCalendar", {
                    roomId: this.roomId,
                    calendarMsgId: this.calendarMsg.id
                  })
                  .then(() => {
                    const dateCalendarIndex = this.firebaseMessages.findIndex(
                      date => date.id === this.calendarMsg.id
                    );
                    if (dateCalendarIndex) {
                      this.firebaseMessages.splice(dateCalendarIndex, 1);
                    }
                  });
                this.targetCalendar = false;
              }
              this.showSuggest = false;
              this.inputMsg = "";
              this.sendNotification();
            })
            .catch(error => {
              throw error;
            });
        }
        const arrUserIds = Object.keys(this.readUsers).filter(
          userId => this.readUsers[userId] === false
        );
        const dataMail = {
          userIds: arrUserIds,
          roomId: ""
        };
        this.$store.dispatch("firebase/sendEmail", dataMail);
        let textarea = document.querySelector("textarea");
        if (window.innerWidth > 1080) {
          textarea.style.cssText = "height:auto; height: 52px;";
        } else {
          textarea.style.cssText = "height:auto; height: 40px;";
        }
        this.calculateHeight();
        this.readonlyMsg = 0;
      }
      this.days = [];
      // }
    },
    handleSubmitKeyboard(e) {
      if (
        !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        // this.handleSubmit(e);
        let val = e.target.value;
        let selStart = e.target.selectionStart;
        e.target.value =
          val.slice(0, selStart) + "\n" + val.slice(e.target.selectionEnd);
        e.target.selectionEnd = e.target.selectionStart = selStart + 1;
        if (e.target.selectionEnd === e.target.textLength) {
          let textarea = document.getElementById("textarea");
          textarea.scrollTop = textarea.scrollHeight;
        }
      } else {
        let val = e.target.value;
        let selStart = e.target.selectionStart - 1;
        e.target.value =
          val.slice(0, selStart) + "\n" + val.slice(e.target.selectionEnd);
        e.target.selectionEnd = e.target.selectionStart = selStart + 1;
      }
    },
    HandleInputFile(event) {
      if (this.authUser.sex === 2 && this.authUser.identity_status != 3) {
        this.$refs.identifyPopup.openModal();
        return;
      }
      const today = new Date();
      const createdAt =
        this.authUser.profile_completed_created_at != null
          ? new Date(this.authUser.profile_completed_created_at)
          : new Date(this.authUser.created_at);
      const dateFreeNum = this.daysBetween(today, createdAt);
      if (
        this.authUser.sex === 1 &&
        !this.monthlyPlanStatus &&
        dateFreeNum > this.chatFreeDay
      ) {
        this.$refs.addMonthlyPlanPopup.openModal();
        return;
      }
      let filesInput = event.target.files;
      if (filesInput[0].size / 1024 / 1024 > 5) {
        this.$toast(
          "画像サイズが大きいためアップロードできません。5MB以下の画像を選択して下さい。",
          "通知",
          "danger"
        );
      } else {
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          this.imageUrl = fileReader.result;
          this.inputImg = event.target.files[0];
          if (this.inputImg !== "") {
            let storageRef = storage.ref();
            const date = new Date();
            const fileName =
              date.getFullYear() +
              "_" +
              this.formatTime(date.getMonth() + 1) +
              "_" +
              this.formatTime(date.getDate()) +
              "_" +
              this.formatTime(date.getHours()) +
              this.formatTime(date.getMinutes()) +
              this.formatTime(date.getSeconds());
            const imgRef = storageRef.child(
              `rooms/${this.roomId}/users/${this.authUser.user_id}/${fileName}`
            );
            imgRef
              .put(this.inputImg)
              .then(() => {
                imgRef.getDownloadURL().then(url => {
                  let data = {
                    roomId: this.roomId,
                    message: {
                      type: 1,
                      userId: this.authUser.user_id,
                      message: "",
                      imageUrl: url,
                      giftId: null,
                      createdAt: new Date(),
                      readUsers: this.readUsers
                    }
                  };
                  this.inputMsg = "";
                  this.inputImg = "";
                  this.imageUrl = "";
                  document.querySelector("#file-input").value = "";
                  this.$store
                    .dispatch("firebase/createMessage", data)
                    .then(() => {
                      this.sendNotification();
                    })
                    .catch(error => {
                      throw error;
                    });
                });
              })
              .catch(error => {
                throw error;
              });
            const arrUserIds = this.firebaseMembers.reduce(
              (userIds, member) => {
                if (parseInt(member.userId) !== this.authUser.user_id) {
                  userIds.push(parseInt(member.userId));
                }

                return userIds;
              },
              []
            );
            const dataMail = {
              userIds: arrUserIds,
              roomId: ""
            };
            this.$store.dispatch("firebase/sendEmail", dataMail);
          }
        });
        fileReader.readAsDataURL(filesInput[0]);
        this.showOther = false;
      }
    },
    showOtherOptions(type) {
      document.querySelector("html").style.cssText =
        "overflow: hidden; height: 100vh";
      document.querySelector("body").style.cssText =
        "overflow: hidden; height: 100vh";
      // if (
      //   (this.authUser.sex === 2 && this.authUser.identity_status != 3) ||
      //   (this.authUser.sex === 1 && !this.monthlyPlanStatus)
      // ) {
      //   this.showSuggest = false;
      // } else {
      //   this.showSuggest = true;
      // }
      this.showGiftShop = false;
      this.showGift = false;
      if (type === "hide") {
        if (this.authUser.sex === 2) {
          this.showOther = true;
          // this.focusMessageOut();
        } else {
          this.showOther = false;
        }
      } else {
        this.showOther = !this.showOther;
      }
      // this.calculateHeight();
    },
    scrollToBottom() {
      let chatBox = document.querySelector(".chat");
      chatBox.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
      if (this.calendarRef) {
        this.calendar = this.$refs[
          this.calendarRef
        ][0].$refs.calendar.$refs.popup.$el;
      }
    },
    scrollToElement(messageId) {
      if (
        messageId == this.firebaseMessages[0].id ||
        this.firebaseMessages.length <= 20
      ) {
        this.scrollToBottom();
      }
    },
    // loadImage() {
    //   document.querySelector(".content__image-msg").scrollIntoView();
    // },
    focusMessageIn(event) {
      document.querySelector("html").style.cssText =
        "overflow: hidden; height: 100%";
      document.querySelector("body").style.cssText =
        "overflow: hidden; height: 100%";
      this.calculateHeight();
      this.showOther = false;
      // this.calculateHeight();
      this.$nextTick(() => {
        if (window.innerWidth < 1080) {
          document.querySelector(".tool").scrollIntoView();
        }
      });
      let style;
      let el = event.target;
      if (event.keyCode !== 13) {
        if (window.innerWidth < 1080) {
          style = "height: 40px";
        } else {
          style = "height: 52px";
        }
        let cssText = "height: auto; padding: 8px 10px;" + style;
        el.style.cssText = cssText;
        el.style.cssText = "height:" + el.scrollHeight + "px";
      } else {
        event.preventDefault();
      }
    },
    pressEnter(event) {
      if (
        event.keyCode === 13 &&
        !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        event.preventDefault();
      }
    },
    focusMessageOut() {
      this.checkMessageFocus = false;
      setTimeout(() => {
        document.querySelector("html").style.cssText =
          "overflow: unset; height: 100vh";
        document.querySelector("body").style.cssText =
          "overflow: unset; height: 100vh";
        document.querySelector("html").scrollTop = 0;
        document.querySelector("body").scrollTop = 0;
      }, 100);

      document.getElementById("message-detail").scrollTop = 0;

      this.$nextTick(() => {
        this.calculateHeight();
      });
    },
    focusMessage() {
      if (this.authUser.sex === 2 && this.authUser.identity_status != 3) {
        this.readonlyMsg = 1;
        this.$store.dispatch("firebase/updateRoomCheckDisplayPopup", {
          roomId: this.roomId,
          value: SEND_CONTINUE_POPUP.DISPLAY_POPUP
        });
        this.$refs.identifyPopup.openModal();
        return;
      }

      const today = new Date();
      const createdAt =
        this.authUser.profile_completed_created_at != null
          ? new Date(this.authUser.profile_completed_created_at)
          : new Date(this.authUser.created_at);
      const dateFreeNum = this.daysBetween(today, createdAt);

      if (
        this.authUser.sex === 1 &&
        !this.monthlyPlanStatus &&
        dateFreeNum > this.chatFreeDay
      ) {
        this.readonlyMsg = 1;
        this.$refs.addMonthlyPlanPopup.openModal();
        return;
      }
      // document.querySelector("html").style.cssText =
      //   "overflow: hidden; height: 80vh";
      // document.querySelector("body").style.cssText =
      //   "overflow: hidden; height: 80vh";
      // if (
      //   (this.authUser.sex === 2 && this.authUser.identity_status != 3) ||
      //   (this.authUser.sex === 1 && !this.monthlyPlanStatus)
      // ) {
      //   this.showSuggest = false;
      // } else {
      //   this.showSuggest = false;
      // }

      this.showGiftShop = false;
      this.showGift = false;
      this.checkMessageFocus = true;
      this.$nextTick(() => {
        this.calculateHeight();
      });
    },
    async femaleChangeEventStatus(type) {
      // type = met || end
      let applicantType;
      switch (type) {
        case 0:
          applicantType = "met";
          break;
        case 1:
          applicantType = "end";
          break;
      }
      // if (type === 1) {
      //   const userIds = [
      //     {
      //       user_id: this.adsRoom.user_id,
      //       to_user_id: this.authUser.user_id
      //     },
      //     {
      //       user_id: this.authUser.user_id,
      //       to_user_id: this.adsRoom.user_id
      //     }
      //   ];
      //   const listRoom = await this.getListRoomSystem(userIds);
      //   data = {
      //     rooms: listRoom
      //   };
      // }
      service({
        url: `${ADS_API_URL}/${this.firebaseRoom.adId}/${applicantType}`,
        method: "PUT"
      })
        .then(() => {
          if (type === 0) {
            Vue.set(this.firebaseApplicant, "status", 1);
          }
          if (type === 1) {
            Vue.set(this.firebaseApplicant, "status", 2);
          }
        })
        .catch(error => {
          this.$toast(error.response.data.error.message, "通知", "danger");
          throw error;
        });
      this.openConfirm = false;
    },
    sendNotification() {
      this.$store.dispatch("firebase/sendPushNotification", {
        to_user_ids: _.uniq(this.listUser)
      });
    },
    sendReason(reason) {
      this.$root.$refs.loading.start();
      let listGirl = [];
      for (
        let indexGirl = 0;
        indexGirl < this.firebaseRoom.userIds.length;
        indexGirl++
      ) {
        if (this.firebaseRoom.userIds[indexGirl] !== this.authUser.user_id) {
          listGirl.push(this.firebaseRoom.userIds[indexGirl]);
        }
      }
      const messageWomen = `${this.authUser.nickname}さんより合流できない連絡<br>
        がありました。<br>
        理輔 <br>
        <span style="color: #F3705A">${reason}</span>`;
      const messageMen = `現在確認中です。しばらくお待<br>
        ちください。`;
      this.$store
        .dispatch("orderManagement/delayOrder", {
          userIds: listGirl,
          userId: this.authUser.user_id,
          adId: this.adsRoom.ad_id,
          roomId: this.firebaseRoom.id,
          messageWomen: messageWomen,
          messageMen: messageMen
        })
        .then(response => {
          if (response?.message) {
            this.$toast(response?.message, "通知", "danger");
            return;
          }
          this.$set(this.firebaseRoom, "isCancel", 1);
          this.$root.$refs.loading.finish();
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    sendReport(reportToUsers = []) {
      this.showReportConfirm = false;
      this.$root.$refs.loading.start();
      if (!reportToUsers.length) {
        if (this.authUser.sex === 1) {
          this.firebaseRoom.userIds.forEach(item => {
            if (item !== this.authUser.user_id) {
              reportToUsers.push(item);
            }
          });
        } else {
          reportToUsers.push(this.adsRoom.user_id);
        }
      }

      let params = {
        userIds: reportToUsers,
        userId: this.authUser.user_id,
        userName: this.authUser.nickname,
        adId: this.adsRoom.ad_id,
        roomId: this.firebaseRoom.id
      };
      if (this.authUser.sex === 2) {
        params = {
          ...params,
          isCast: 1
        };
      }
      this.$store
        .dispatch("orderManagement/reportOrder", params)
        .then(response => {
          this.$root.$refs.loading.finish();
          if (response?.message) {
            this.$toast(response?.message, "通知", "danger");
            return;
          }
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    handleShowReport() {
      this.showReport = true;
      this.$store
        .dispatch("orderManagement/getUsersReportOrder", {
          userId: this.authUser.user_id,
          adId: this.adsRoom.ad_id
        })
        .then(response => {
          this.userReports = response.data || [];
        });
    },
    identify() {
      if (this.authUser.sex === 1) {
        if (this.$route.name !== "MenMyPageIdentifyProfile")
          this.$router.push({ name: "MenMyPageIdentifyProfile" });
        this.$refs.identifyPopup.closeModal();
        return;
      }
      if (this.$route.name !== "CastMyPageIdentifyProfile")
        this.$router.push({ name: "CastMyPageIdentifyProfile" });
      this.$refs.identifyPopup.closeModal();
    },
    async closeContinueDatePopup() {
      let data = {
        roomId: this.roomId,
        message: {
          type: MESSAGES_TYPE.CALENDAR,
          userId: this.authUser.user_id,
          message: "",
          imageUrl: "",
          giftId: null,
          createdAt: new Date(),
          from: this.authUser.sex,
          readUsers: this.readUsers
        }
      };
      await this.$store
        .dispatch("firebase/createMessage", data)
        .then(() => {
          const dataRoom = {
            roomId: this.roomId,
            value: SEND_CONTINUE_POPUP.ADD_MSG
          };
          this.$store.dispatch(
            "firebase/updateRoomSendContinuePopup",
            dataRoom
          );
          this.showSuggest = true;
          this.inputMsg = "";
        })
        .catch(error => {
          throw error;
        });

      this.showContinueDatePopup = false;
    },
    async closeAddMonthlyPlanPopup() {
      this.$refs.addMonthlyPlanPopup.closeModal();
    },
    changeInputMess(mess) {
      this.targetCalendar = false;
      this.checkMessageFocus = true;
      if (mess == "") {
        this.readonlyMsg = 0;
        this.showOther = true;
      } else {
        this.readonlyMsg = 1;
        this.showOther = false;
      }
      this.inputMsg = mess;
      if (window.innerWidth <= 415) {
        let textarea = document.querySelector("textarea");
        if (textarea) {
          if (mess.length > 14) {
            textarea.style.cssText = "height:auto; height: 64px;";
          } else {
            textarea.style.cssText = "height:auto; height: 40px;";
          }
        }
      }
    },
    changeText() {
      if (this.inputMsg === "" && this.targetCalendar) {
        this.targetCalendar = false;
      }
    },
    showProfileOfUser() {
      if (this.firebaseMembers[0].id != 0) {
        if (this.firebaseMembers[0].sex == 1) {
          this.$router.push({
            name: "MenProfile",
            params: { id: this.firebaseMembers[0].id }
          });
        } else {
          this.$router.push({
            name: "CastProfile",
            params: { id: this.firebaseMembers[0].id }
          });
        }
      }
    },
    msgSuggestToggle() {
      let elBtn = document.getElementById("btn-show-sticker");
      elBtn.classList.toggle("rotate-btn");
      let checkShowOrHide = document.getElementsByClassName("show-stk");
      if (checkShowOrHide.length > 0) {
        this.showSuggest = false;
      } else {
        this.showSuggest = true;
      }
      this.calculateHeight();
    },

    daysBetween(today, date2) {
      // The number of milliseconds in one day
      const onedDay = 1000 * 60 * 60 * 24;
      // Calculate the difference in milliseconds
      const differenceMs = Math.abs(today - date2);
      // Convert back to days and return
      return Math.round(differenceMs / onedDay);
    }
  },
  updated() {
    if (this.firebaseMembers && this.firebaseOwner) {
      this.readUsers = [
        ...this.firebaseMembers,
        ...[this.firebaseOwner]
      ].reduce((members, mem) => {
        members[mem.id] =
          parseInt(mem.id) === this.authUser.user_id ? true : false;
        return members;
      }, {});
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/MessageDetail.scss";
#message-detail {
  /deep/ .app-body._header .app-container__content {
    background-color: #f5f5f5;
  }
}
#message-detail {
  overflow-y: hidden;
  /deep/ #card-footer {
    z-index: 0 !important;
  }
  @media screen and (max-width: 1080px) {
    /deep/ #card-footer {
      position: unset !important;
    }
  }
  .nickname-cl {
    cursor: pointer;
  }
}
.form-message {
  background-color: #ffffff;
  flex-grow: 1;
  border-radius: 10px;
  box-shadow: 0 -3px 15px #00000008;
}
#textarea[readonly] {
  -webkit-user-modify: read-only;
}

@media only screen and (max-width: 376px) {
  .app-modal.active {
    overflow: auto;
    padding-top: 70px;
  }
}

.not-show-sticker {
  display: none !important;
}

.btn-show-sticker {
  margin-left: -13px;
  margin-right: 6px;
}

.rotate-btn {
  transform: rotate(-180deg) !important;
}
</style>
